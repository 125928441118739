import React from "react"

export default function Header() {
    return <div>
        <header>
            <div className="header">
                <h1 className="main-title">Liz P. Hughson</h1>
                <p className="subtitle">Website Strategy - SEO Expert - Front-End Web Developer - People Manager</p>
                <p className="callout">Open to Remote Opportunities</p>
                <div className="d-flex justify-content-center mt-3">
                    <div className="container-contact">
                        <a href="#contact" className="btn btn-primary">Contact Me</a>
                    </div>
                </div>
              </div>
        </header>
    </div>
}