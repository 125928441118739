import React from "react"

export default function Contact() {
    return <section id="contact" className="section-blurb">
        <h2>Contact</h2>
        <div className="row">
            <div className="col text-center">
                <p>Please <a href="https://www.linkedin.com/in/elizabethpeirce" target="_blank" rel="noreferrer">contact me on LinkedIn</a> if you'd like to work together.</p>
            </div>
            {/*<div className="col-lg-8">*/}
            {/*    <form className="form-contact">*/}
            {/*        <div className="form-group">*/}
            {/*            <label htmlFor="name" className="form-label">Name</label>*/}
            {/*            <input type="text" className="form-control" id="name" placeholder="Enter Name"/>*/}
            {/*        </div>*/}
            {/*        <div className="form-group">*/}
            {/*            <label htmlFor="email" className="form-label">Email Address</label>*/}
            {/*            <input type="text" className="form-control" id="email" placeholder="Enter Email Address"/>*/}
            {/*        </div>*/}
            {/*        <div className="form-group">*/}
            {/*            <label htmlFor="message" className="form-label">Message</label>*/}
            {/*            <textarea className="form-control" id="message" rows="4" placeholder="Message"></textarea>*/}
            {/*        </div>*/}
            {/*        <button type="submit" className="btn btn-primary">Submit</button>*/}
            {/*    </form>*/}
            {/*</div>*/}
        </div>

    </section>
}