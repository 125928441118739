import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import React from "react";
import Header from "./components/Header";
import About from "./components/About";
// import Navbar from "./components/Navbar";
// import Projects from "./components/Projects";
import Skills from "./components/Skills";
import Contact from "./components/Contact";
import Footer from "./components/Footer";

function App(props) {
  return (
     <div>
         {/*<Navbar />*/}
         <div className="main-body container">
             <Header />
             <About />
             {/*<Projects />*/}
             <Skills />
             <Contact />
         </div>
        <Footer/>
     </div>
  );
}

export default App;
