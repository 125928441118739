import React from "react"
import headshot_liz from "../headshot_liz.jpeg";

export default function About() {
    return <section className="section-blurb" id="about">
        <h2>About</h2>
        <div className="row">
            <div className="col-md-3">
                <img src={headshot_liz} alt="Liz headshot" className="img-fluid"/>
            </div>
            <div className="col-md-9">
                <div className="pt-4 pt-md-0 ps-md-4">
                    <p>I'm Liz, a <strong>website strategist</strong> with a passion for crafting digital
                        experiences that revolve around user needs.</p>
                    <p>Drawing from a decade of experience in diverse web technologies, UI/UX design and SEO, my
                        primary interests include the management of large-scale websites using a <strong>data-driven approach</strong>. I
                        rely on data to make informed decisions, optimize performance, and enhance user experiences. Check out my <a href="#skills">skills</a> to learn more.</p>
                    <p>I am open to remote or in person opportunities in the greater Boston area. <a href="#contact">Contact me</a> to see how we can work together.</p>
                </div>
            </div>
        </div>
    </section>
}