export const skills = [
    {
        title: "Website Strategy",
        icon: "pin-angle-fill",
        yoe:
            "10+",
        example: "Strategic Planning, Website Development and Maintenance, Conversion Rate Optimization, SEO Strategy, Digital Marketing",
    },
    {
        title: "Technical SEO",
        icon: "pin-angle-fill",
        yoe:
            "5+",
        example: "Core Web Vitals, Accessibility, Page Speed, Mobile-Friendliness, Structured Data",
    },
    {
        title: "Front-End Web Development and UX/UI Design",
        icon: "pin-angle-fill",
        yoe:
            "10+",
        example: "HTML, CSS, Javascript, Ruby on Rails, Bootstrap, Wireframe and Prototyping",
    },
    {
        title: "Analytics",
        icon: "pin-angle-fill",
        yoe:
            "5+",
        example: "Google Analytics, Looker Studio, Event and Conversion Tracking, Google Tag Manager",
    }
]