import React, { useState }  from "react"

function getYear() {
    const today = new Date();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const date = today.getDate();
    return `${year}`;
}

export default function Footer() {
    const [currentDate] = useState(getYear());

    return <div>
        <footer>
            <p>&copy; {currentDate} Elizabeth Hughson | Greater Boston </p>
        </footer>
</div>
}