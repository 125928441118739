import React from "react"
import {skills} from '../data';

export default function Skills() {
    return <section id="skills" className="section-blurb">
        <h2>Skills</h2>
        <div className="row justify-content-around">
        {skills.map((skill) => (
            <div className="col-lg-3 mb-4 mb-md-0" key={skill.title}>
                    <p>
                        <span className="pe-2">
                             <i className={`bi-${skill.icon}`} ></i>
                        </span>
                        {skill.title}
                    </p>
                {/*<p className="fs-6">Years of Experience: {skill.yoe}</p>*/}
                <p className="fs-6">{skill.example}</p>
            </div>
        ))}
        </div>
</section>
}